<template>
  <div class="auditDatails">
    <template>
      <div class="baseInfo info">
        <p class="pub_head">基本信息</p>
        <div class="pub_cont">
          <el-descriptions title="">
            <el-descriptions-item label="学校">{{
              orderDetailsData.schoolName
            }}</el-descriptions-item>
            <el-descriptions-item label="批次">{{
              orderDetailsData.admissionBatch
            }}</el-descriptions-item>
            <el-descriptions-item label="教学点">{{
              orderDetailsData.stationName
            }}</el-descriptions-item>
            <el-descriptions-item label="省份">{{
              orderDetailsData.province
            }}</el-descriptions-item>
            <el-descriptions-item label="提交时间">{{
              timestampReturnDate(orderDetailsData.createTime, "datetime")
            }}</el-descriptions-item>
            <el-descriptions-item label="提交人">{{
              orderDetailsData.createBy
            }}</el-descriptions-item>
            <el-descriptions-item label="审批单人数" v-if="$route.query.feeType == 2 && $route.query.type == 'watch'">{{
              orderDetailsData.studentNum
            }}</el-descriptions-item>
            <el-descriptions-item label="审批单编号">{{
              orderDetailsData.number
            }}</el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <div class="payInfo info">
        <p class="pub_head">审批单信息</p>
        <div class="pub_cont">
          <div class="school_smallArea">
            <p class="small_title">学生名单</p>
            <div>
              <studentListUrl :orderDetailsData="orderDetailsData"></studentListUrl>
            </div>
          </div>
          <!-- <el-descriptions title="支付信息" :class="['sub_title', $route.query.feeType == 2 ? 'hiddenM':'']">
            <el-descriptions-item label="学生数量">{{
              orderDetailsData.studentNum
            }}</el-descriptions-item>
            <el-descriptions-item label="应收金额">{{
              orderDetailsData.amountReceivable
            }}</el-descriptions-item>
            <el-descriptions-item label="实收金额">
              <span v-if="!isEditBaseNew">{{ orderDetailsData.amountActual }}</span>
              <el-input v-else class="s-input" v-model="editForm.amountActual" clearable @input="trimLR('amountActual')"></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="待收金额">{{
              orderDetailsData.uncollectedAmount
            }}</el-descriptions-item>
            <el-descriptions-item label="收费单价">{{
              orderDetailsData.unitPrice
            }}</el-descriptions-item>
          </el-descriptions> -->
          <!-- <el-descriptions
            title="付款信息"
            class="sub_title"
            v-if="feetype == '1'"
          >
            <el-descriptions-item label="付款账号">
              <span v-if="!isEditBaseNew">{{ orderDetailsData.payAccount }}</span>
              <el-input v-else class="s-input" v-model="editForm.payAccount" clearable></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="付款户名">
              <span v-if="!isEditBaseNew">{{orderDetailsData.payAccountName}}</span>
              <el-input v-else class="s-input" v-model="editForm.payAccountName" clearable></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="付款银行">
              <span v-if="!isEditBaseNew">{{ orderDetailsData.payBank }}</span>
              <el-input v-else class="s-input" v-model="editForm.payBank" clearable></el-input>
            </el-descriptions-item>
            <el-descriptions-item label="付款时间">
              <span v-if="!isEditBaseNew">{{ timestampReturnDate(orderDetailsData.payTime, "datetime") }}</span>
              <el-date-picker
                v-else
                v-model="editForm.payTime"
                type="datetime"
                clearable
                class="times"
                placeholder="付款时间"
                value-format="timestamp">
              </el-date-picker>
            </el-descriptions-item>
            <el-descriptions-item label="到款时间">
              <span v-if="!isEditBaseNew">{{ timestampReturnDate(orderDetailsData.arrivalTime, "datetime") }}</span>
              <el-date-picker
                v-else
                v-model="editForm.arrivalTime"
                type="datetime"
                clearable
                class="times"
                placeholder="付款时间"
                value-format="timestamp">
              </el-date-picker>
            </el-descriptions-item>
          </el-descriptions> -->
          <div class="audit_smallArea">
            <p class="small_title">审批凭证</p>
            <div class="pic_showArea" v-if="!isEditBaseNew">
              <img
                v-for="(item, index) in orderDetailsData.voucherUrls"
                :key="index"
                class="audit_pic"
                :src="item"
                alt=""
                @click="watchEvent(orderDetailsData.voucherUrls, index)"
              />
            </div>
            <el-upload
              v-else
              action="#"
              class="elUploads"
              list-type="picture-card"
              ref="uploads"
              accept="image/png, image/jpeg, image/jpg"
              :auto-upload="false"
              :limit="5"
              :file-list="fileData"
              :on-exceed="exceedUpload"
              :on-change="changeEvent"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
          <el-descriptions
            title="审批单开票"
            class="sub_title"
          >
            <el-descriptions-item label="是否开票" v-if="orderDetailsData.status != 1">
              <span v-if="!isEditBaseNew">{{$common.invoiceFlagData[orderDetailsData.invoiceFlag]}}</span>
              <el-radio-group v-model="editForm.invoiceFlag" v-else>
                <el-radio v-for="(item, key, index) in $common.invoiceFlagData" :key="index" :label="Number(key)">{{item}}</el-radio>
              </el-radio-group>
            </el-descriptions-item>
            <template v-if="($route.query.type == 'edit' && editForm.invoiceFlag == 1) || ($route.query.type == 'watch' && orderDetailsData.invoiceFlag == 1 && orderDetailsData.status != 1) || ($route.query.type == 'watch' && orderDetailsData.status == 1 && orderDetailsData.invoiceFlag >= 1) || ($route.query.type == 'audit' && orderDetailsData.invoiceFlag == 1)">
              <el-descriptions-item label="开票形式">
                <span v-if="!isEditBaseNew">{{ $common.invoicingMethodData[orderDetailsData.invoiceMethod] }}</span>
                <el-select
                  v-model="editForm.invoiceMethod"
                  placeholder="开票形式"
                  filterable
                  v-else
                >
                  <el-option
                    v-for="(item, key, index) in $common.invoicingMethodData"
                    :key="index"
                    :label="item"
                    :value="key"
                  >
                  </el-option>
                </el-select>
              </el-descriptions-item>
              <!-- <el-descriptions-item label="发票种类">
                <span v-if="!isEditBaseNew">{{ $common.invoiceKindData[orderDetailsData.invoiceKind] }}</span>
                <el-select
                  v-model="editForm.invoiceKind"
                  placeholder="发票种类"
                  filterable
                  @change="changeinK"
                  v-else
                >
                  <el-option
                    v-for="(item, key, index) in $common.invoiceKindData"
                    :key="index"
                    :label="item"
                    :value="Number(key)"
                  >
                  </el-option>
                </el-select>
              </el-descriptions-item> -->
              <!-- <el-descriptions-item label="发票类型">
                <span v-if="!isEditBaseNew">{{ $common.invoiceTypeData[orderDetailsData.invoiceType] }}</span>
                <el-select
                  v-model="editForm.invoiceType"
                  placeholder="发票类型"
                  filterable
                  v-else
                >
                  <el-option
                    v-for="(item, key, index) in $common.invoiceTypeData"
                    :key="index"
                    :label="item"
                    :value="Number(key)"
                  >
                  </el-option>
                </el-select>
              </el-descriptions-item> -->
              <el-descriptions-item label="抬头类型">
                <span v-if="!isEditBaseNew">{{ $common.headTypeData[orderDetailsData.headType] }}</span>
                <el-select
                  v-model="editForm.headType"
                  placeholder="抬头类型"
                  filterable
                  @change="changeHeadt"
                  v-else
                >
                  <el-option
                    v-for="(item, key, index) in $common.headTypeData"
                    :key="index"
                    :label="item"
                    :value="Number(key)"
                  >
                  </el-option>
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item label="发票金额">
                <span v-if="!isEditBaseNew">{{ orderDetailsData.invoiceAmount }}</span>
                <el-input v-else class="s-input" v-model="editForm.invoiceAmount" clearable @input="trimLR('invoiceAmount')"></el-input>
              </el-descriptions-item>
              <el-descriptions-item label="发票抬头">
                <span v-if="!isEditBaseNew">{{ orderDetailsData.headName }}</span>
                <el-input v-else class="s-input" v-model="editForm.headName" clearable @input="trimLR('headName')"></el-input>
              </el-descriptions-item>
              <el-descriptions-item label="税号">
                <span v-if="!isEditBaseNew">{{ orderDetailsData.dutyParagraph || '/' }}</span>
                <el-input v-else class="s-input" v-model="editForm.dutyParagraph" clearable :disabled="editForm.headType == 1" @input="trimLR('dutyParagraph')"></el-input>
              </el-descriptions-item>
              <el-descriptions-item label="发送邮箱" v-if="$route.query.type == 'edit' || $route.query.type == 'watch' || $route.query.type == 'audit'">
                <span v-if="!isEditBaseNew">{{ orderDetailsData.email || '/' }}</span>
                <el-input v-else class="s-input" v-model="editForm.email" clearable @input="trimLR('email')" @blur="regE"></el-input>
              </el-descriptions-item>
              <!-- <el-descriptions-item label="邮寄地址" v-if="(editForm.invoiceKind == 2 && $route.query.type == 'edit') || (orderDetailsData.invoiceKind == 2 && $route.query.type == 'watch') || ($route.query.type == 'audit' && orderDetailsData.invoiceKind == 2)">
                <span v-if="!isEditBaseNew">{{ orderDetailsData.address || '/' }}</span>
                <el-input v-else class="s-input" v-model="editForm.address" clearable @input="trimLR('address')"></el-input>
              </el-descriptions-item> -->
              <template v-if="orderDetailsData.status == 1">
                <el-descriptions-item label="开票状态">
                  {{ $common.invoiceStatusData[orderDetailsData.invoiceFlag] }}
                </el-descriptions-item>
                <el-descriptions-item label="发票文件">
                  <span class="btn-txt" @click="downInvoiceEvent(orderDetailsData.invoiceUrl)">下载</span>
                </el-descriptions-item>
                <el-descriptions-item label="发票文件导入时间">
                  {{orderDetailsData.invoiceImportTime ? timestampReturnDate(orderDetailsData.invoiceImportTime, "datetime") : '/'}}
                </el-descriptions-item>
                <el-descriptions-item label="物流单号">
                  <span>{{ orderDetailsData.shipmentNo || '/' }}</span>
                </el-descriptions-item>
                <el-descriptions-item label="快递公司">
                  <span>{{ orderDetailsData.logisticsCompany || '/' }}</span>
                </el-descriptions-item>
                <el-descriptions-item label="发票号">
                  <span>{{ orderDetailsData.invoiceNo || '/' }}</span>
                </el-descriptions-item>
              </template>
            </template>
          </el-descriptions>
          <div class="school_smallArea" v-if="false">
            <p class="small_title">学生名单</p>
            <!-- <el-button @click="downLoadFile(orderDetailsData.studentListUrl)" type="primary" plain>下载文档</el-button> -->
            <div class="studentlist">
              <el-button
                @click="toStudentList($route.query)"
                type="primary"
                plain
                >查看学生名单</el-button
              >
              <div class="downLoadFile">
                <span style="color: red;">{{errorTips}}</span>
                <!-- <span>下载：<a :href="orderDetailsData.studentListUrl">学生名单.xls</a></span> -->
                <!-- <div @click="downLoadFile(orderDetailsData.studentListUrl)">
                  学生名单.xls
                </div> -->
              </div>
            </div>
            <!-- <router-view></router-view> -->

            <!-- <span>南昌.xls</span> -->
          </div>
          <div class="remark_smallArea">
            <p class="small_title">备注</p>
            <el-input
              v-if="!isEditBaseNew"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              class="text_area"
              placeholder="请输入"
              v-model="orderDetailsData.remark"
              disabled
            >
            </el-input>
            <el-input
              v-else
              class="text_re"
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="editForm.remark">
            </el-input>
          </div>
        </div>
      </div>
      <div class="payFees info" v-if="orderDetailsData.status == 1">
        <p class="pub_head">补缴记录</p>
        <div class="pub_cont">
          <div class="hang">
            <el-button
              class="mainBtn"
              @click="paymentEvent"
              plain
              v-show="orderDetailsData.feeStatus < 1"
              >新增补缴</el-button
            >
          </div>
          <el-table
            :data="orderDetailsData.makeUps"
            stripe
            border
            style="width: 100%"
            max-height="500"
            align="center"
          >
            <el-table-column label="序号" align="center" width="80">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column prop="makeUpMoney" label="补缴金额" align="center">
            </el-table-column>
            <el-table-column prop="makeUpTime" label="补缴时间" align="center">
              <template slot-scope="scope">
                <span>{{
                  timestampReturnDate(scope.row.makeUpTime, "datetime")
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="voucherUrls" label="补缴凭证" align="center">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.voucherUrls.length > 0"
                  @click="watchEvent(scope.row.voucherUrls)"
                  size="small"
                  >查看凭证</el-button
                >
                <!-- <el-image
                    ref="picS"
                    style="width: 30px; height: 30px; display: none;"
                    :src="scope.row.voucherUrls[0]" 
                    :preview-src-list="scope.row.voucherUrls">
                  </el-image> -->
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="补缴成功学生数" align="center">
              <template slot-scope="scope">
                <el-button
                  @click="watchDetail(scope.row)"
                  size="small"
                  type="text"
                  >{{ scope.row.successNumber || '/' }}</el-button
                >
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注" align="center">
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.remark"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div class="ellipsis">{{ scope.row.remark || "/" }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column label="详情" align="center">
              <template slot-scope="scope">
                <el-button
                  @click="dowmLoadEvent(scope.row)"
                  size="small"
                  type="text"
                  v-if="scope.row.failStudentUrl"
                  >查看结果</el-button
                >
                <span v-else>/</span>
              </template>
            </el-table-column>
            <!-- <el-table-column
              fixed="right"
              label="操作"
              width="100"
              align="center">
              <template slot-scope="scope">
                <el-popconfirm title="确定删除吗？" @confirm="deleteEvent(scope.row)">
                  <el-button slot="reference" class="pub_style" type="text">删除</el-button>
                </el-popconfirm>
              </template>
            </el-table-column> -->
          </el-table>
          <div class="btn_pay_area">
            <el-button
              class="mainBtn"
              @click="closeUpPayEvent"
              plain
              v-show="orderDetailsData.feeStatus < 1"
              v-allow="'sys_audit_close_up'"
              >关闭审批单</el-button
            >
            <!-- <el-button class="mainBtn" @click="goBack" plain>返 回</el-button> -->
          </div>
        </div>
      </div>
      <div class="payInfo info" v-if="orderDetailsData.status != 3">
        <p class="pub_head">审批操作</p>
        <div class="pub_cont">
          <div class="audit_opinion" v-if="types == 'audit'">
            <p class="small_title">审批意见</p>
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              class="text_area"
              placeholder="请输入"
              v-model="form.approvalComments"
            >
            </el-input>
            <div class="btn_audit_area">
              <el-button
                type="warning"
                plain
                @click="auditDatilsEvent('refuse')"
                >审核不通过</el-button
              >
              <el-button type="primary" @click="auditDatilsEvent('agree')"
                >审核通过</el-button
              >
              <el-button
                @click="goBack"
                >返回列表</el-button
              >
            </div>
          </div>
          <div class="audit_opinion" v-else>
            <el-descriptions title="审批信息" class="sub_title">
              <el-descriptions-item label="审批人">{{
                orderDetailsData.verifyBy
              }}</el-descriptions-item>
              <el-descriptions-item label="审批时间">{{
                timestampReturnDate(orderDetailsData.approveTime, "datetime")
              }}</el-descriptions-item>
              <el-descriptions-item label="审批状态">{{
                String(orderDetailsData.status) == "0"
                  ? "待审核"
                  : String(orderDetailsData.status) == 1
                  ? "审核通过"
                  : "审核不通过"
              }}</el-descriptions-item>
              <el-descriptions-item label="审批意见">{{
                orderDetailsData.approvalComments || "暂无"
              }}</el-descriptions-item>
              <!-- <el-descriptions-item label="操作内容">{{
                orderDetailsData.approvalComments || "暂无"
              }}</el-descriptions-item>
              <el-descriptions-item label="操作人">{{
                orderDetailsData.approvalComments || "暂无"
              }}</el-descriptions-item>
              <el-descriptions-item label="操作时间">{{
                timestampReturnDate(orderDetailsData.approveTime, "datetime")
              }}</el-descriptions-item> -->
            </el-descriptions>
            <el-button
              type="primary"
              v-if="
                orderDetailsData.status == 0 &&
                !orderDetailsData.uncollectedAmount
              "
              @click="goBack"
              >返回列表</el-button
            >
          </div>
        </div>
      </div>
      <div class="notPass-btn" v-if="($route.query.status == 2 || $route.query.status == 3) && $route.query.type == 'edit'">
        <!-- <el-button type="primary" @click="editBase">编辑基本信息</el-button> -->
        <el-button type="primary" @click="editEvent('save')">保存</el-button>
        <el-button type="primary" @click="resubmit">{{orderDetailsData.status == 2 ? '重新提交审批单' : '提交审核'}}</el-button>
        <el-button @click="goBack">返回列表</el-button>
      </div>
      <div class="back-area" v-if="$route.query.type == 'watch'">
        <el-button
          @click="goBack"
          >返回列表</el-button
        >
      </div>
      <el-image-viewer
        v-if="showViewer"
        :initial-index="showIndex"
        :on-close="watchEvent"
        :url-list="voucherUrlsPic"
      />
    </template>
    <dialogDiy
      :isShow="isCloseShow"
      :dialogDiyData="dialogDiyData"
      @dialogEvent="dialogDiyEvent"
    >
      <div slot="dialogContent" class="dia_opera">
        <img class="errIcon" src="../../assets/img/warn.png" alt="" />
        <p>{{diaType == 1 ? '确定要关闭该审批单吗？' : '确定要重新提交该审批单吗？'}}</p>
        <div class="btn_area">
          <el-button @click="batchDelete">取 消</el-button>
          <el-button class="mainBtn" @click="doEvent">确 定</el-button>
        </div>
      </div>
    </dialogDiy>
    <!-- 补缴 -->
    <el-dialog
      class="compile"
      title="补缴操作"
      :visible.sync="isShowPayment"
      @close="cancelPay"
    >
      <el-form :model="forms" label-position="right" label-width="120px" :rules="rules">
        <!-- <el-form-item label="补缴金额:" prop="makeUpMoney">
          <el-input
            @keyup.enter.native="paymentEvents"
            v-model="forms.makeUpMoney"
            clearable
          ></el-input>
        </el-form-item> -->
        <el-form-item label="补缴范围:">
          <el-radio-group v-model="forms.makeUpRang">
            <el-radio :label="1">全部补缴</el-radio>
            <el-radio :label="2">部分补缴</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="studentListUrl" v-if="forms.makeUpRang == 2">
          <template slot="label">
            <span class="is-write">* </span>
            <span>缴费学生名单:</span>
          </template>
          <div class="small_upload">
            <div class="opera_left">
              <img src="../../assets/img/file.png" />
              <span>学生名单样例</span>
              <el-button class="mainBtn" @click="downLoadEvent"
                >点击下载</el-button
              >
            </div>
            <div class="opera_right">
              <div
                class="bor"
                @click="getLocalFile"
                v-if="!forms.studentListUrl"
              >
                <img src="../../assets/img/adds.png" />
                <span>学生名单样例</span>
              </div>
              <div class="bor noBorder" @click="getLocalFile" v-else>
                {{ forms.fileName }}
              </div>
              <!-- <el-button class="mainBtn" @click="">确认上传</el-button> -->
            </div>
          </div>
        </el-form-item>
        <el-form-item label="上传付款凭证:">
          <el-upload
            action="#"
            class="elUploads"
            list-type="picture-card"
            ref="upload"
            accept="image/png, image/jpeg, image/jpg"
            :auto-upload="false"
            :limit="5"
            :on-exceed="exceedUpload"
            :on-change="changeEvent"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <!-- <div class="uploads" @click="getLocalPic" v-if="forms.voucherUrls.length == 0">
            <img src="../../assets/img/adds.png" alt="">
          </div>
          <template v-else>
            <img class="up_img" :src="forms.voucherUrls" alt="">
          </template> -->
        </el-form-item>
        <el-form-item label="备注:">
          <el-input
            type="textarea"
            placeholder="备注"
            v-model="forms.remark"
            maxlength="500"
            show-word-limit
            @keyup.enter.native="paymentEvents"
            :autosize="{ minRows: 2, maxRows: 6 }"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelPay">取 消</el-button>
        <el-button type="primary" @click="paymentEvents">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改基本信息 -->
    <el-dialog
      class="edit-base"
      title="编辑"
      :visible.sync="isEditBase"
    >
      <div class="edit-model" v-if="this.feetype == 1">
        <el-form :model="editForm" label-position="right" label-width="100px" :inline="true">
          <el-form-item label="付款账户">
            <el-input v-model="editForm.payAccount" clearable></el-input>
          </el-form-item>
          <el-form-item label="付款户名">
            <el-input v-model="editForm.payAccountName" clearable></el-input>
          </el-form-item>
          <el-form-item label="付款银行">
            <el-input v-model="editForm.payBank" clearable></el-input>
          </el-form-item>
          <el-form-item label="付款时间">
            <el-date-picker
              v-model="editForm.payTime"
              type="datetime"
              clearable
              class="times"
              placeholder="付款时间"
              value-format="timestamp">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="到款时间">
            <el-date-picker
              v-model="editForm.arrivalTime"
              type="datetime"
              clearable
              class="times"
              placeholder="付款时间"
              value-format="timestamp">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="实收金额" prop="amountActual">
            <el-input v-model="editForm.amountActual" clearable></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="edit-model">
        <p class="area_head">上传付款凭证</p>

        <el-upload
          action="#"
          class="elUploads"
          list-type="picture-card"
          ref="uploads"
          accept="image/png, image/jpeg, image/jpg"
          :auto-upload="false"
          :limit="5"
          :file-list="fileData"
          :on-exceed="exceedUpload"
          :on-change="changeEvent"
          :on-preview="handlePictureCardPreview"
          :on-remove="handleRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>
      <div class="edit-model">
        <p class="area_head">备注</p>
        <el-input
          class="text_re"
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="editForm.remark">
        </el-input>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editEvent">确 定</el-button>
        <el-button @click="editBase">取 消</el-button>
      </div>
    </el-dialog>

    <!-- 查看补缴名单 -->
    <playBackList ref="pB"></playBackList>
    
    <input
      style="display: none"
      type="file"
      @change="picChange()"
      ref="loadPic"
      accept="image/*"
    />
    <input
      style="display: none;"
      type="file"
      @change="fileChange()"
      ref="loadFile"
    />
  </div>
</template>

<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import dialogDiy from "../commonModule/dialogDiy";
import studentListUrl from "./studentListUrl";
import playBackList from "./auditManage/playBackList";
import way from "../../api/encapsulation";
const OSS = require("ali-oss");
import { excelFileUpload } from "../../api/fileUpload"
export default {
  name: "auditDatails",
  components: {
    ElImageViewer,
    dialogDiy,
    studentListUrl,
    playBackList
  },
  data() {
    return {
      id: "",
      feetype: "",
      types: "",
      orderDetailsData: {},
      form: {
        approvalComments: "",
        id: "",
        status: "",
        taskFlag: 0,
      },
      loading: false,
      isShowPayment: false,
      forms: {
        verifyId: "",
        // makeUpMoney: "",
        remark: "",
        voucherUrls: [],
        fileName: null,
        studentListUrl: null,
        makeUpRang: 1
      },
      voucherUrlsPic: [],
      showViewer: false,
      isShowAuditPic: false,
      isCloseShow: false,
      dialogDiyData: {
        title: "",
        sizeStyle: { width: "400px", height: "234px" },
      },
      fileData: [],
      showIndex: 0,
      isEditBase: false,
      editForm: {},
      diaType: 1,
      editForm: {
        address: null,
        email: null,
        amountActual: null,
        arrivalTime: null,
        dutyParagraph: null,
        feeType: null,
        headName: null,
        headType: null,
        id: null,
        invoiceAmount: null,
        invoiceFlag: null,
        invoiceMethod: null,
        // invoiceKind: null,
        // invoiceType: null,
        isResubmit: false,
        payAccount: null,
        payAccountName: null,
        payBank: null,
        payTime: null,
        remark: null,
        voucherUrls: []
      },
      errorTips: '',
      rules: {
        // makeUpMoney: [
        //   { required: true, message: "请输入补缴金额", trigger: "blur" }
        // ]
      },
      isEditBaseNew: false,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(newVal, oldVal) {
        this.feetype = String(newVal.query.feeType);
        this.types = newVal.query.type;
        this.id = newVal.query.id;
        this.getAudioDetail();
      },
    },
  },
  methods: {
    timestampReturnDate: way.timestampReturnDate,
    trimLR(name) {
      this.editForm[name] = way.clearSpace(this.editForm[name]);
      let arr = ['amountActual', 'invoiceAmount']
      if (arr.includes(name) && !this.$way.regNumber(this.editForm[name])) {
        this.Warn('该项请填数字')
        this.editForm[name] = null
      }
    },
    // 获取审批页面数据
    getAudioDetail() {
      this.loading = true;
      this.$request.auditQueryById({ id: this.id }, (res) => {
        this.loading = false;
        if (res.code == 0) {
          // console.log(res.data);
          this.orderDetailsData = res.data;
          // this.getAuditErrTipsData();
          this.isEditBaseNew = (this.orderDetailsData.status == 2 || this.orderDetailsData.status == 3) && this.$route.query.type == 'edit' ? true : false
          // console.log(this.isEditBaseNew)
          if(this.$route.query.type == 'edit') this.getBaseData();
        }
      });
    },
    getBaseData() {
      Object.assign(this.editForm, this.orderDetailsData)
      this.$set(this.editForm, 'id', this.id)
      this.fileData = [];
      if(this.editForm.voucherUrls.length != 0) {
        this.editForm.voucherUrls.forEach((item, index) => {
          this.fileData.push({
            name: 'voucher',
            url: item
          })
        })
      }
    },
    editEvent(type) {
      if (type == 'save') this.editForm.isResubmit = false
      let arr = this.fileData.map(item=>item.raw);
      let vouArr = this.fileData.filter(item=>item.name == 'voucher').map(item => item.url);
      let filterArr = arr.filter(Boolean);
      // console.log(arr)
      // console.log(vouArr)
      // console.log(filterArr)
      if(filterArr.length != 0) {
        // 多图片上传
        this.$request.uploadPics(filterArr).then( res=>{
          if(res.data.code == 0) {
            // console.log(res.data);
            vouArr.push.apply(vouArr, res.data.data);
            this.editForm.voucherUrls = vouArr;
            this.editAuditEvent();
          }
        })
      } else {
        this.editForm.voucherUrls = vouArr
        this.editAuditEvent();
      }
    },
    editAuditEvent() {
      this.$request.auditEdit(this.editForm).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg || '保存成功');
          this.getAudioDetail();
          // this.editBase();
          this.isCloseShow = false
          this.goBack()
        }
      })
    },
    // 下载文档
    downLoadFile(url) {
      if (!url) {
        this.Warn("当前无文件可下载");
        return;
      }
      window.location.href = url;
    },

    // 查看学生名单
    toStudentList(row) {
      this.$router.push({
        name: "studentListUrl",
        query: {
          id: row.id,
          status: row.status || this.$route.query.status,
          feeType: row.feeType,
          type: this.$route.query.type,
          moduleType: 1
        },
      });
      // this.$router.go(0)
    },
    // 获取学生名单错误信息
    getAuditErrTipsData() {
      this.$request.getAuditErrTips({id: this.id}, res => {
        if(res.code == 0) {
          this.errorTips = res.data > 0 ? '该名单存在错误数据，请点击查验' : '';
        }
      })
    },
    // 审核通过或审核不通过
    auditDatilsEvent(type) {
      this.form.id = this.id;
      this.form.taskFlag = this.orderDetailsData.taskFlag;
      this.form.status = type == "refuse" ? 2 : 1;
      if (this.form.taskFlag != 1) {
        this.Warn("审批单正在处理中无法审批,稍后重试......");
        return;
      }
      this.$request.approvalProcess(this.form).then((res) => {
        if (res.data.code == 0) {
          this.Success(res.data.msg);
          this.goBack();
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    // 补缴
    paymentEvent(row) {
      this.isShowPayment = !this.isShowPayment;
    },
    paymentEvents() {
      // if (!this.forms.makeUpMoney) {
      //   this.Warn("补缴金额不为空！");
      //   return;
      // }
      // if (
      //   this.forms.makeUpMoney > this.orderDetailsData.uncollectedAmount ||
      //   Number(this.forms.makeUpMoney) < 0
      // ) {
      //   this.Warn("补缴金额输入有误,不能输入负数或者大于待收金额");
      //   return;
      // }
      if (!this.forms.studentListUrl && this.forms.makeUpRang == 2) {
        this.Warn("缴费学生名单未上传");
        return;
      }
      if (this.fileData.length != 0) {
        let arr = this.fileData.map((item) => item.raw);
        // 多图片上传
        this.$request.uploadPics(arr).then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data)
            this.forms.voucherUrls = res.data.data;
            this.enterPay();
          }
        });
      } else {
        this.enterPay();
      }
    },
    // 确定补缴
    enterPay() {
      // console.log(this.forms);
      this.forms.verifyId = this.id;
      this.$request.makeUpEvent(this.forms).then((res) => {
        if (res.data.code == 0) {
          this.Success(res.data.msg);
          this.isShowPayment = false;
          this.getAudioDetail();
        }
      });
    },
    cancelPay() {
      this.isShowPayment = false;
      // this.forms.makeUpMoney = "";
      this.forms.remark = "";
      this.forms.voucherUrls = [];
    },
    // 确认上传
    getLocalPic() {
      this.$refs.loadPic.value = "";
      this.$refs.loadPic.click();
    },
    async picChange() {
      let inputDOM = this.$refs.loadPic;
      let files = inputDOM.files[0];
      this.fileName = files.name;
      let ossUrl = await way.uploadOss(OSS, files, "img/auditDatails");
      console.log(ossUrl);
      this.forms.voucherUrls.push(ossUrl);
    },
    // 删除
    deleteEvent(row) {
      this.$request.deleteByAuditPayId({ id: row.id }, (res) => {
        if (res.code == 0) {
          this.Success(res.msg);
          this.getAudioDetail();
        }
      });
    },
    // 查看凭证
    watchEvent(urlArr, showIndex = 0) {
      this.showViewer = !this.showViewer;
      if (this.showViewer) {
        // console.log(showIndex, 777);
        this.voucherUrlsPic = urlArr;
        this.showIndex = showIndex;
      }
    },
    closeUpPayEvent() {
      this.diaType = 1;
      this.isCloseShow = !this.isCloseShow;
    },
    doEvent() {
      if(this.diaType == 1) {
        this.$request.closeUpPay({ id: this.id }).then((res) => {
          if (res.data.code == 0) {
            this.Success(res.data.data);
            this.goBack();
          }
        });
      } else {
        this.editEvent()
        // this.$request.auditReSubmit({id: this.id}).then(res => {
        //   if(res.data.code == 0) {
        //     this.Success(res.data.msg);
        //     this.goBack();
        //   }
        // })
      }
    },
    dialogDiyEvent(val) {
      this.isCloseShow = val.isShow;
    },
    batchDelete() {
      this.isCloseShow = !this.isCloseShow;
    },
    exceedUpload() {
      this.Warn("最大图片上传数量为5");
    },
    async changeEvent(file, fileList) {
      // console.log(file, fileList,2223222222);
      // if (file.raw.size > 1024 * 100) {
      //   this.$message.error("海报大于100KB,不能上传");
      //   return;
      // }
      this.fileData = fileList;
    },
    handleRemove(file, fileList) {
      this.fileData = fileList;
    },
    handlePictureCardPreview(file) {
      this.voucherUrlsPic = [file.url];
      this.showViewer = true;
    },
    editBase() {
      this.isEditBase = !this.isEditBase;
    },
    resubmit() {
      this.editForm.isResubmit = true
      this.diaType = 2;
      this.isCloseShow = !this.isCloseShow;
    },
    changeHeadt() {
      this.editForm.dutyParagraph = this.editForm.headType == 1 ? null : this.orderDetailsData.dutyParagraph
    },
    // changeinK() {
    //   this.editForm.email = this.editForm.invoiceKind == 1 ? this.orderDetailsData.email : null
    //   this.editForm.address = this.editForm.invoiceKind == 2 ? this.orderDetailsData.address : null
    // },
    // 校验邮箱
    regE() {
      if (!this.editForm.email) return;
      this.editForm.email = this.$way.regEmailNew(this.editForm.email) ? this.editForm.email : null
    },
    cincoEvent() {
      // let obj = {
      //   invoiceKind: this.editForm.invoiceFlag == 1 ? this.orderDetailsData.invoiceKind : null,
      //   invoiceType: this.editForm.invoiceFlag == 1 ? this.orderDetailsData.invoiceType : null,
      //   headType: this.editForm.invoiceFlag == 1 ? this.orderDetailsData.headType : null,
      //   invoiceAmount: this.editForm.invoiceFlag == 1 ? this.orderDetailsData.invoiceAmount : null,
      //   headName: this.editForm.invoiceFlag == 1 ? this.orderDetailsData.headName : null,
      //   dutyParagraph: this.editForm.invoiceFlag == 1 ? this.orderDetailsData.dutyParagraph : null
      // }
      // Object.assign(this.editForm, obj)
    },
    // 发票下载
    downInvoiceEvent(url) {
      if (url)
        window.location.href = url
      else
        this.Warn('暂无文件下载')
    },
    // 下载模板
    downLoadEvent() {
      this.$request.getModule({ labelName: 'verifyStudentImport' }).then((res) => {
        if (res.data.code == 0) {
          window.location.href = res.data.data;
        }
      });
    },
    getLocalFile() {
      this.$refs.loadFile.value = "";
      this.$refs.loadFile.click();
    },
    async fileChange() {
      let inputDOM = this.$refs.loadFile;
      let files = inputDOM.files[0];
      let obj = await excelFileUpload(files);
      if (obj) {
        this.forms.fileName = obj.fileName;
        this.forms.studentListUrl = obj.excelUrl;
      }
    },
    watchDetail(row) {
      this.$refs.pB.showEvent(row)
    },
    dowmLoadEvent(row) {
      if(row.failStudentUrl) window.location.href = row.failStudentUrl
    }
  },
};
</script>

<style lang="less">
.auditDatails {
  height: calc(~"100% - 68px");
  background-color: #fff;
  box-sizing: border-box;
  padding: 20px 40px;
  .info {
    margin-top: 20px;
    border: 1px solid #f2f2f2;
  }
  .pub_head {
    height: 60px;
    line-height: 60px;
    box-sizing: border-box;
    padding-left: 29px;
    background: #f2f2f2;
    font-size: 18px;
    font-weight: 600;
  }
  .pub_cont {
    box-sizing: border-box;
    padding: 20px 40px;
    .sub_title {
      .el-descriptions__title {
        color: #1282ff;
        border-left: 3px solid #1282ff;
        padding-left: 11px;
        font-weight: 500;
      }
      .el-descriptions-item__container {
        align-items: center;
      }
      .s-input .el-input__inner {
        width: 130px;
      }
    }
    .small_title {
      color: #1282ff;
      border-left: 3px solid #1282ff;
      padding-left: 11px;
      margin-bottom: 20px;
    }
    .studentlist {
      height: 40px;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .downLoadFile {
        display: flex;
        line-height: 40px;
        margin-left: 40px;
        font-size: 12px;
        a{
        font-size: 12px;
        color: #000;
        }
      }
    }
  }
  .baseInfo {
    .info_area {
      display: flex;
      padding-top: 40px;
      background: #ffffff;
      border: 1px solid #f2f2f2;
      & > ul {
        margin-left: 40px;
        img {
          width: 100px;
          height: 140px;
        }
      }
    }
  }
  .payInfo {
    .pic_showArea {
      display: flex;
      .audit_pic {
        width: 100px;
        height: 100px;
        background-color: #e1e1e1;
        margin-bottom: 20px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
    .text_area {
      width: 400px;
    }
    .btn_audit_area {
      margin-top: 20px;
    }
  }
  .payFees {
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #fff;
    }
    ::-webkit-scrollbar-thumb {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
      border-right: none;
    }
    .btn_pay_area {
      text-align: center;
      margin-top: 20px;
    }
  }
  .notPass-btn {
    margin-top: 20px;
    text-align: center;
  }
  .edit-base {
    .edit-model {
      border-bottom: 1px solid #E1E1E1;
      padding-bottom: 20px;
      .area_head {
        padding: 10px 0;
      }
    }
  }
  .back-area {
    text-align: center;
    margin-top: 20px;
  }
}
.el-image-viewer__wrapper {
  z-index: 9999!important;
}
</style>

<style lang="less" scoped>
  // .billArea {
  //   font-size: 14px;
  //   color: #606266;
    .billRadio {
      margin-bottom: 10px;
      & > span {
        margin-right: 10px;
      }
    }
    .bill-form {
      // display: flex;
      // flex-wrap: wrap;
      // /deep/ .el-form-item {
      //   width: 180px;
      // }
    }
  // }
  .is-write {
    color: red;
  }
  /deep/ .el-descriptions-item__label {
    display: inline-block;
    width: 116px;
  }
  .btn-txt {
    cursor: pointer;
    color: #1282ff;
    font-size: 14px;
  }
  .small_upload {
    display: flex;
    box-sizing: border-box;
    padding: 30px 0;
    .opera_left,
    .opera_right {
      height: 180px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
    }
    .opera_left {
      // width: 200px;
      flex: 1;
      > img {
        width: 51px;
        height: 51px;
      }
      > span {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }
    .opera_right {
      // width: 188px;
      flex: 2;
      .bor {
        width: 188px;
        height: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        border: 2px dashed #e1e1e1;
        cursor: pointer;
        > img {
          width: 25px;
          height: 25px;
        }
        > span {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
      }
      .noBorder {
        word-wrap: break-word;
        border: 0 none;
      }
    }
  }
</style>