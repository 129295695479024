<template>
  <div class="playBackList">
    <dialogDiyNew :isShow="isShowStuDetail" :dialogDiyData="dialogStuDiyData" @dialogEvent="dialogStuEvent">
      <div slot="dialogContent" class="stu_detail_area">
        <el-table
          :data="tableData"
          stripe
          border
          style="width: 100%"
          height="600"
          align="center"
        >
          <el-table-column label="序号" align="center" width="60">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="studentNum" label="学生学号" align="center"></el-table-column>
          <el-table-column prop="studentName" label="学生姓名" align="center"></el-table-column>
        </el-table>
        <Pagination
          @changePage="changePage"
          :childMsg="childMsg"
        ></Pagination>
      </div>
    </dialogDiyNew>
  </div>
</template>

<script>
import dialogDiyNew from '@/components/commonModule/dialogDiyNew'
import Pagination from "@/components/Pagination"
export default {
  name: 'playBackList',
  components: {
    dialogDiyNew,
    Pagination
  },
  data () {
    return {
      isShowStuDetail: false,
      dialogStuDiyData: {
        title: '补缴学生名单',
        sizeStyle: {width: '700px',height: '800px'}
      },
      tableData: [],
      childMsg: {
        current: 1,
        size: 10,
        total: 1,
      },
      formInline: {
        verifyId: this.$route.query.id,
        currentPage: 1,
        pageSize: 10,
        makeUpId: null
      },
    }
  },
  methods: {
    showEvent(obj) {
      // console.log(obj, 'obj')
      this.formInline.makeUpId = obj.id
      this.dialogStuEvent()
      this.getTableData();
    },
    dialogStuEvent() {
      this.isShowStuDetail = !this.isShowStuDetail
    },
    getTableData() {
      this.$request.getStudentNames(this.formInline, (res) => {
        if (res.code == 0) {
          let { records, current, size, total } = res.data;
          this.tableData = records;
          this.childMsg = {
            current,
            size,
            total
          };
        }
      })
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.currentPage = val.current;
      this.formInline.pageSize = val.size;
      this.getTableData();
    },
  }
}
</script>

<style lang="less" scoped>
  .playBackList {
    .stu_detail_area {
      padding: 20px;
      box-sizing: border-box;
    }
    /deep/.el-pagination {
      text-align: right;
    }
  }
</style>